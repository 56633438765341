import Vue from "vue";
import VueRouter from "vue-router";
/**
 * 重写路由的push方法
 */
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error);
};

// 路由懒加载
const Authorization = () => import("../components/Authorization.vue");
const Login = () => import("../views/Login.vue");
const Index = () => import("../views/Index.vue");
const Welcome = () => import("../views/auth/index/Welcome.vue");
const RoleMenu = () => import("../views/auth/roleMenu/RoleMenu.vue");
const BigData = () => import("../views/bigData/BigData.vue");
const employeeManagement = () =>
  import("../views/auth/employee/EmployeeManagement.vue");
const employeeDetail = () =>
  import("../views/auth/employee/EmployeeDetail.vue");
const OrganizationManagement = () =>
  import("../views/auth/organization/OrganizationManagement.vue");
const Post = () => import("../views/auth/post/Post.vue");
const Menu = () => import("../views/auth/menu/Menu.vue");
const Authority = () => import("../views/auth/authority/Authority.vue");
const OrganizationTypeMaintenance = () =>
  import("../views/auth/organizationType/OrganizationType.vue");
const myAccountView = () => import("../views/auth/my/MyAccountView.vue");
const SystemConfig = () => import("../views/systemConfig/SystemConfig.vue");
const systemConfigDetails = () =>
  import("../views/systemConfig/SystemConfigDetails.vue");
const ActivityAddress = () =>
  import("../views/activityAddress/ActivityAddress.vue");
const ActivityAddressDetails = () =>
  import("../views/activityAddress/ActivityAddressDetails.vue");
const ActivityAddressTime = () =>
  import("../views/activityAddressTime/ActivityAddressTime.vue");
const ActivityAddressTimeDetails = () =>
  import("../views/activityAddressTime/ActivityAddressTimeDetails.vue");
const ActivityFile = () => import("../views/activityFile/ActivityFile.vue");
const ActivityFileDetails = () =>
  import("../views/activityFile/ActivityFileDetails.vue");
const ActivityProduct = () =>
  import("../views/activityProduct/ActivityProduct.vue");
const ActivityProductDetails = () =>
  import("../views/activityProduct/ActivityProductDetails.vue");
const Community = () => import("../views/community/Community.vue");
const CommunityDetails = () =>
  import("../views/community/CommunityDetails.vue");
const Server = () => import("../views/server/Server.vue");
const ServerDetails = () => import("../views/server/ServerDetails.vue");
const Street = () => import("../views/street/Street.vue");
const StreetDetails = () => import("../views/street/StreetDetails.vue");
const Supplier = () => import("../views/supplier/Supplier.vue");
const SupplierDetails = () => import("../views/supplier/SupplierDetails.vue");
const SupplierOrder = () => import("../views/supplierOrder/SupplierOrder.vue");
const SupplierOrderDetails = () =>
  import("../views/supplierOrder/SupplierOrderDetails.vue");
const SupplierOrderDetail = () =>
  import("../views/supplierOrderDetail/SupplierOrderDetail.vue");
const SupplierOrderDetailDetails = () =>
  import("../views/supplierOrderDetail/SupplierOrderDetailDetails.vue");
const SupplierProduct = () =>
  import("../views/supplierProduct/SupplierProduct.vue");
const SupplierProductDetails = () =>
  import("../views/supplierProduct/SupplierProductDetails.vue");
const SupplierProductCategory = () =>
  import("../views/supplierProductCategory/SupplierProductCategory.vue");
const SupplierProductCategoryDetails = () =>
  import("../views/supplierProductCategory/SupplierProductCategoryDetails.vue");
const Village = () => import("../views/village/Village.vue");
const VillageDetails = () => import("../views/village/VillageDetails.vue");
const VillageMap = () => import("../views/village/villageMap.vue");
const Area = () => import("../views/area/Area.vue");
const AreaDetails = () => import("../views/area/AreaDetails.vue");
const Member = () => import("../views/member/Member.vue");
const MemberDetails = () => import("../views/member/MemberDetails.vue");
const MemberActivity = () =>
  import("../views/memberActivity/MemberActivity.vue");
const MemberActivityDetails = () =>
  import("../views/memberActivity/MemberActivityDetails.vue");
const MemberActivitySignup = () =>
  import("../views/memberActivitySignup/MemberActivitySignup.vue");
const MemberActivitySignupDetails = () =>
  import("../views/memberActivitySignup/MemberActivitySignupDetails.vue");
const StreetMap = () => import("../views/street/StreetMap.vue");
const StreetServerType = () =>
  import("../views/streetServerType/StreetServerType.vue");
const CommunityMap = () => import("../views/community/CommunityMap.vue");
const StreetServerTypeDetails = () =>
  import("../views/streetServerType/StreetServerTypeDetails.vue");
const SupplierServer = () =>
  import("../views/supplierServer/SupplierServer.vue");
const SupplierServerDetails = () =>
  import("../views/supplierServer/SupplierServerDetails.vue");
const SupplierServerOrder = () =>
  import("../views/supplierServerOrder/SupplierServerOrder.vue");
const SupplierServerOrderDetails = () =>
  import("../views/supplierServerOrder/SupplierServerOrderDetails.vue");
const SupplierServerOrderDetail = () =>
  import("../views/supplierServerOrderDetail/SupplierServerOrderDetail.vue");
const SupplierServerOrderDetailDetails = () =>
  import(
    "../views/supplierServerOrderDetail/SupplierServerOrderDetailDetails.vue"
  );
const SupplierServerType = () =>
  import("../views/supplierServerType/SupplierServerType.vue");
const SupplierServerTypeDetails = () =>
  import("../views/supplierServerType/SupplierServerTypeDetails.vue");
const VillageBuild = () => import("../views/villageBuild/VillageBuild.vue");
const VillageBuildDetails = () =>
  import("../views/villageBuild/VillageBuildDetails.vue");
const VillageRoom = () => import("../views/villageRoom/VillageRoom.vue");
const VillageRoomDetails = () =>
  import("../views/villageRoom/VillageRoomDetails.vue");
const VillageUnit = () => import("../views/villageUnit/VillageUnit.vue");
const VillageUnitDetails = () =>
  import("../views/villageUnit/VillageUnitDetails.vue");
const VillageDutyPerson = () =>
  import("../views/villageDutyPerson/VillageDutyPerson.vue");
const VillageDutyPersonDetails = () =>
  import("../views/villageDutyPerson/VillageDutyPersonDetails.vue");
const StreetDeliverySite = () =>
  import("../views/streetDeliverySite/StreetDeliverySite.vue");
const StreetDeliverySiteDetails = () =>
  import("../views/streetDeliverySite/StreetDeliverySiteDetails.vue");
const StreetDeliverySiteAdd = () =>
  import("../views/streetDeliverySite/StreetDeliverySiteAdd.vue");
const StreetGridManage = () =>
  import("../views/streetGridManage/StreetGridManage.vue");
const StreetGridManageDetails = () =>
  import("../views/streetGridManage/StreetGridManageDetails.vue");
const StreetGridManageAdd = () =>
  import("../views/streetGridManage/StreetGridManageAdd.vue");
const StreetSos = () => import("../views/streetSos/StreetSos.vue");
const StreetSosDetails = () =>
  import("../views/streetSos/StreetSosDetails.vue");
const StreetStarPage = () =>
  import("../views/streetStarPage/StreetStarPage.vue");
const StreetStarPageDetails = () =>
  import("../views/streetStarPage/StreetStarPageDetails.vue");
const Grid = () => import("../views/grid/Grid.vue");
const GridDetails = () => import("../views/grid/GridDetails.vue");
const GridMap = () => import("../views/grid/GridMap.vue");
const PrintPage = () => import("../views/supplierOrder/printPage.vue");
const Kong = () => import("../views/member/kong.vue");

Vue.use(VueRouter);

const routes = [
  { path: "/", redirect: "/authorization" },
  { path: "/authorization", component: Authorization },
  { path: "/login", component: Login },
  {
    path: "/index",
    component: Index,
    redirect: "/index",
    children: [
      { path: "/bigData", component: BigData },
      { path: "/index", component: Kong },
      { path: "/employee", component: employeeManagement },
      { path: "/employee/details", component: employeeDetail },
      { path: "/organization", component: OrganizationManagement },
      { path: "/post", component: Post },
      { path: "/menu", component: Menu },
      { path: "/authority", component: Authority },
      { path: "/organizationType", component: OrganizationTypeMaintenance },
      { path: "/myAccountView", component: myAccountView },
      { path: "/roleMenu", component: RoleMenu },
      { path: "/systemConfig", component: SystemConfig },
      { path: "/systemConfig/details", component: systemConfigDetails },
      { path: "/activityAddress", component: ActivityAddress },
      { path: "/activityAddress/details", component: ActivityAddressDetails },
      { path: "/activityAddressTime", component: ActivityAddressTime },
      {
        path: "/activityAddressTime/details",
        component: ActivityAddressTimeDetails
      },
      { path: "/activityFile", component: ActivityFile },
      { path: "/activityFile/details", component: ActivityFileDetails },
      { path: "/activityProduct", component: ActivityProduct },
      { path: "/activityProduct/details", component: ActivityProductDetails },
      { path: "/community", component: Community },
      { path: "/community/details", component: CommunityDetails },
      { path: "/server", component: Server },
      { path: "/server/details", component: ServerDetails },
      { path: "/street", component: Street },
      { path: "/street/details", component: StreetDetails },
      { path: "/supplier", component: Supplier },
      { path: "/supplier/details", component: SupplierDetails },
      { path: "/supplierOrder", component: SupplierOrder },
      { path: "/supplierOrder/details", component: SupplierOrderDetails },
      { path: "/supplierOrderDetail", component: SupplierOrderDetail },
      {
        path: "/supplierOrderDetail/details",
        component: SupplierOrderDetailDetails
      },
      { path: "/supplierProduct", component: SupplierProduct },
      { path: "/supplierProduct/details", component: SupplierProductDetails },
      { path: "/supplierProductCategory", component: SupplierProductCategory },
      {
        path: "/supplierProductCategory/details",
        component: SupplierProductCategoryDetails
      },
      { path: "/village", component: Village },
      { path: "/village/details", component: VillageDetails },
      { path: "/village/Map", component: VillageMap },
      { path: "/area", component: Area },
      { path: "/area/details", component: AreaDetails },
      { path: "/member", component: Member },
      { path: "/member/details", component: MemberDetails },
      { path: "/memberActivity", component: MemberActivity },
      { path: "/memberActivity/details", component: MemberActivityDetails },
      { path: "/memberActivitySignup", component: MemberActivitySignup },
      {
        path: "/memberActivitySignup/details",
        component: MemberActivitySignupDetails
      },
      { path: "/street/Map", component: StreetMap },
      { path: "/streetServerType", component: StreetServerType },
      { path: "/Community/Map", component: CommunityMap },
      { path: "/streetServerType/details", component: StreetServerTypeDetails },
      { path: "/supplierServer", component: SupplierServer },
      { path: "/supplierServer/details", component: SupplierServerDetails },
      { path: "/supplierServerOrder", component: SupplierServerOrder },
      {
        path: "/supplierServerOrder/details",
        component: SupplierServerOrderDetails
      },
      {
        path: "/supplierServerOrderDetail",
        component: SupplierServerOrderDetail
      },
      {
        path: "/supplierServerOrderDetail/details",
        component: SupplierServerOrderDetailDetails
      },
      { path: "/supplierServerType", component: SupplierServerType },
      {
        path: "/supplierServerType/details",
        component: SupplierServerTypeDetails
      },
      { path: "/villageBuild", component: VillageBuild },
      { path: "/villageBuild/details", component: VillageBuildDetails },
      { path: "/villageRoom", component: VillageRoom },
      { path: "/villageRoom/details", component: VillageRoomDetails },
      { path: "/villageUnit", component: VillageUnit },
      { path: "/villageUnit/details", component: VillageUnitDetails },
      { path: "/villageDutyPerson", component: VillageDutyPerson },
      {
        path: "/villageDutyPerson/details",
        component: VillageDutyPersonDetails
      },
      { path: "/streetDeliverySite", component: StreetDeliverySite },
      {
        path: "/streetDeliverySite/details",
        component: StreetDeliverySiteDetails
      },
      { path: "/streetDeliverySite/add", component: StreetDeliverySiteAdd },
      { path: "/streetGridManage", component: StreetGridManage },
      { path: "/streetGridManage/details", component: StreetGridManageDetails },
      { path: "/streetGridManage/add", component: StreetGridManageAdd },
      { path: "/streetSos", component: StreetSos },
      { path: "/streetSos/details", component: StreetSosDetails },
      { path: "/streetStarPage", component: StreetStarPage },
      { path: "/streetStarPage/details", component: StreetStarPageDetails },
      { path: "/grid", component: Grid },
      { path: "/grid/details", component: GridDetails },
      { path: "/grid/map", component: GridMap },
      { path: "/printPage", component: PrintPage }
    ]
  }
];

const router = new VueRouter({
  routes
});

// 挂载路由导航守卫,to表示将要访问的路径，from表示从哪里来，next是下一个要做的操作 next('/login')强制跳转login
router.beforeEach((to, from, next) => {
  if (to.path === "/login" || to.path === "/authorization") {
    next();
  } else {
    let token = ""; //token保存在localstorage中

    let item = localStorage.getItem("token");
    // 先将拿到的试着进行json转为对象的形式
    try {
      item = JSON.parse(item);
    } catch (error) {
      // eslint-disable-next-line no-self-assign
      item = item;
    }
    if (item && item.startTime) {
      let date = new Date().getTime();
      // 如果大于就是过期了，如果小于或等于就还没过期
      let timel = date - item.startTime;
      console.log("timelength:" + timel + ",expires:" + item.expires);
      if (timel < item.expires) {
        token = item.value;
      }
    }
    if (token === null || token === "") {
      next("/login");
    } else {
      next();
    }
  }
});

export default router;
